import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

const UserTable = (props) => {
    const loginData = props.data;
    const [dataArray, setDataArray] = useState();
    useEffect(() => {
        if (!loginData) return;
        let filtered = [];
        loginData.map((value, index) => {
            let current = loginData[index];
            let next = loginData[index + 1] ? loginData[index + 1] : null;
            //needs to be over half a minute apart
            if (
                next &&
                dayjs(current.time).diff(dayjs(next.time), "m", true) > 0.5
            ) {
                return filtered.push(current);
            } else if (next && current.user.id !== next.user.id) {
                return filtered.push(current);
            } else if (index === loginData.length - 1) {
                return filtered.push(loginData[index]);
            }
            return filtered;
        });
        setDataArray(filtered);
    }, [loginData]);

    if (!dataArray) return <div></div>;
    return (
        <TableContainer component={Paper} sx={{ width: props.width }}>
            <div
                style={{
                    marginLeft: "1rem",
                    marginTop: "1rem",
                    fontWeight: "bold",
                }}
            >
                AUTHENTICATION HISTORY
            </div>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {props.hasUser && <TableCell>User</TableCell>}
                        <TableCell>App</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell align="right">Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataArray.map((data) => (
                        <TableRow
                            key={data.id}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            {props.hasUser && (
                                <TableCell component="th" scope="row">
                                    {data.user.email}
                                </TableCell>
                            )}
                            <TableCell component="th" scope="row">
                                {data.application}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {dayjs(data.time).format("DD/MM/YY")}
                            </TableCell>
                            <TableCell align="right">
                                {dayjs(data.time).format("HH:mm A")}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserTable;
