import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { PieChart } from "echarts/charts";
import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    DataZoomComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";

//register required components

echarts.use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    PieChart,
    CanvasRenderer,
    DataZoomComponent,
]);

const AssetsUsagePieGraph = (props) => {
    const chartRef = useRef(null);
    const [chartObject, setChartObject] = useState(null);
    const [pageCountData, setPageCountData] = useState();

    useEffect(() => {
        let assets = props.data.assets;
        let goalsCount = 0;
        let issuesCount = 0;
        let promptsCount = 0;
        let requestsCount = 0;
        let reportsCount = 0;
        let assetsCount = 0;
        assets.map((session) => {
            if (session.url.split("/")[3].includes("goals")) {
                goalsCount += 1;
                return goalsCount;
            } else if (session.url.split("/")[3].includes("issues")) {
                issuesCount += 1;
                return issuesCount;
            } else if (session.url.split("/")[3].includes("prompts")) {
                promptsCount += 1;
                return promptsCount;
            } else if (session.url.split("/")[3].includes("requests")) {
                requestsCount += 1;
                return requestsCount;
            } else if (session.url.split("/")[3].includes("reports")) {
                reportsCount += 1;
                return reportsCount;
            } else if (session.url.split("/")[3].includes("assets")) {
                assetsCount += 1;
                return assetsCount;
            } else {
                return null;
            }
        });
        let pagesCount = [
            { name: "Goals", value: goalsCount },
            { name: "Issues", value: issuesCount },
            { name: "Prompts", value: promptsCount },
            { name: "Requests", value: requestsCount },
            { name: "Reports", value: reportsCount },
            { name: "Assets/Asset Details", value: assetsCount },
        ];
        setPageCountData(pagesCount);
    }, [props.data]);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    useEffect(() => {
        if (!chartRef.current || !chartObject || !pageCountData) return;
        let option = {
            title: {
                text: "Referer of a Website",
                subtext: "Fake Data",
                left: "center",
            },
            tooltip: {
                trigger: "item",
            },
            legend: {
                orient: "vertical",
                left: "left",
            },
            series: [
                {
                    name: "Visits",
                    type: "pie",
                    radius: "50%",
                    top: "30%",

                    data: pageCountData,
                },
            ],
        };

        chartObject.setOption(option);
    }, [chartObject, pageCountData, props]);

    return (
        <div
            style={{
                width: props.width,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    paddingBottom: "1rem",
                    fontWeight: "bold",
                }}
            >
                Page Visits Last 30 Days (BM Assets)
            </div>
            <div ref={chartRef} style={{ width: "100%", height: 350 }} />
        </div>
    );
};

export default AssetsUsagePieGraph;
