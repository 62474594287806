import React, { useEffect, useState, useCallback } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {
    Alert,
    Autocomplete,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const ORGANISATIONS_QUERY = gql`
    query getAllOrganisations {
        organisation {
            id
            name
        }
    }
`;

const POLICY_GROUPS_QUERY = gql`
    query getAllPolicies($organisation: Int!) {
        ppcs_core_policy(where: { owner_id: { _eq: $organisation } }) {
            id
            name
        }
        ppcs_core_usergroup(
            where: { organisation_id: { _eq: $organisation } }
        ) {
            id
            name
        }
    }
`;

const INVITE_USER_MUTATION = gql`
    mutation inviteUser($user: NewUserInput!) {
        invite_new_user(user: $user) {
            id
            email
        }
    }
`;

const InviteUserModal = (props) => {
    const [organisations, setOrganisations] = useState([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState();
    const [policies, setPolicies] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedPolicies, setSelectedPolicies] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const [email, setEmail] = useState();
    const [application, setApplication] = useState("app");

    const { data: orgData } = useQuery(ORGANISATIONS_QUERY);
    const [getPoliciesAndGroups, { data: policyAndGroupData }] =
        useLazyQuery(POLICY_GROUPS_QUERY);

    const [inviteUser] = useMutation(INVITE_USER_MUTATION);

    useEffect(() => {
        if (!orgData) return;
        setOrganisations(
            orgData.organisation.map((org, index) => {
                return {
                    value: org.id,
                    label: org.name,
                };
            })
        );
    }, [orgData]);

    useEffect(() => {
        if (!policyAndGroupData) return;
        setPolicies(
            policyAndGroupData.ppcs_core_policy.map((policy, index) => {
                return {
                    value: policy.id,
                    label: policy.name,
                };
            })
        );
        setGroups(
            policyAndGroupData.ppcs_core_usergroup.map((group, index) => {
                return {
                    value: group.id,
                    label: group.name,
                };
            })
        );
    }, [policyAndGroupData]);

    useEffect(() => {
        if (!selectedOrganisation) return;
        getPoliciesAndGroups({
            variables: {
                organisation: selectedOrganisation.value,
            },
        });
    }, [selectedOrganisation, getPoliciesAndGroups]);

    const sendInvite = useCallback(() => {
        if (!email || !selectedOrganisation || !application) return;
        inviteUser({
            variables: {
                user: {
                    email: email,
                    organisation: selectedOrganisation.value,
                    application: application,
                    policy_ids: selectedPolicies.map(
                        (policiy) => policiy.value
                    ),
                    group_ids: selectedGroups.map((group) => group.value),
                },
            },
        })
            .then((r) => {
                setSuccess(true);
            })
            .catch((e) => {
                setError(e.message);
            });
    }, [
        application,
        email,
        selectedOrganisation,
        selectedPolicies,
        selectedGroups,
        inviteUser,
    ]);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={() => {
                    setError(null);
                    props.handleClose();
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{ marginBottom: 2 }}
                        >
                            Invite New User to BenchMarker
                        </Typography>
                        {success && (
                            <div>
                                <div>
                                    Successfully Invited {email} to Benchmarker!
                                    <p>
                                        This user will have an invitation email
                                        in there inbox where they will be
                                        directed to complete their sign up.
                                    </p>
                                    <Button
                                        sx={{ float: "right" }}
                                        color="primary"
                                        onClick={() => {
                                            setError(null);
                                            setSuccess(false);
                                            props.handleClose();
                                        }}
                                    >
                                        Finish
                                    </Button>
                                </div>
                            </div>
                        )}
                        {!success && (
                            <div>
                                <FormControl
                                    sx={{ marginBottom: 2, width: "100%" }}
                                >
                                    {error && (
                                        <Alert
                                            sx={{ marginBottom: 2 }}
                                            severity="error"
                                        >
                                            {error}
                                        </Alert>
                                    )}
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        Application
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="app"
                                        name="radio-buttons-group"
                                        onChange={(e, v) => setApplication(v)}
                                    >
                                        <FormControlLabel
                                            value="app"
                                            control={<Radio />}
                                            label="App"
                                        />
                                        <FormControlLabel
                                            value="assets"
                                            control={<Radio />}
                                            label="Assets"
                                        />
                                        <FormControlLabel
                                            value="shop"
                                            control={<Radio />}
                                            label="Shop"
                                        />
                                    </RadioGroup>
                                    <TextField
                                        sx={{ marginBottom: 2 }}
                                        required
                                        id="standard-required"
                                        label="Email"
                                        variant="standard"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <Autocomplete
                                        sx={{ marginBottom: 2 }}
                                        options={organisations}
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        id="clear-on-escape"
                                        clearOnEscape
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Organisation"
                                                variant="standard"
                                            />
                                        )}
                                        onChange={(e, v) =>
                                            setSelectedOrganisation(v)
                                        }
                                    />
                                    <Autocomplete
                                        multiple
                                        fullWidth
                                        id="tags-outlined"
                                        options={policies}
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Policies"
                                            />
                                        )}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    key={index}
                                                    label={option.label}
                                                    {...getTagProps({ index })}
                                                    onDelete={null}
                                                />
                                            ))
                                        }
                                        onChange={(event, newValue) => {
                                            setSelectedPolicies(newValue);
                                        }}
                                        value={selectedPolicies}
                                    />
                                    <Autocomplete
                                        multiple
                                        fullWidth
                                        id="tags-outlined"
                                        options={groups}
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Groups"
                                            />
                                        )}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    key={index}
                                                    label={option.label}
                                                    {...getTagProps({ index })}
                                                    onDelete={null}
                                                />
                                            ))
                                        }
                                        onChange={(event, newValue) => {
                                            setSelectedGroups(newValue);
                                        }}
                                        value={selectedGroups}
                                    />
                                </FormControl>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Button
                                        color="secondary"
                                        onClick={() => {
                                            setError(null);
                                            setSuccess(false);
                                            props.handleClose();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={sendInvite}>Invite</Button>
                                </Box>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default InviteUserModal;
